import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const url = process.env.REACT_APP_API_URL;
const api = `${url}/api`;

const buildApiUrl = (endpoint) => `${api}${endpoint}`;

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    headers: { "Content-Type": "application/json charset=utf-8 " },
  }),

  endpoints: (builder) => ({
    get: builder.query({
      queryFn: async (
        { endpoint, credentials },
        _queryApi,
        _options,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: endpoint,
          method: "GET",
          headers: {
            credentials,
            "Content-Type": "application/json; charset=utf-8",
          },
        });

        if ("error" in result) {
          console.log("ERROR:", result.error);
          // Можно добавить дополнительную информацию:
          return { error: { ...result.error, endpointAddress: endpoint } };
        }

        console.log("SUCCESS:", result);
        return {
          data: result.data ?? {},
        };
      },
    }),
    add: builder.mutation({
      query: ({ endpoint, credentials, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          credentials,
          "Content-Type": "application/json; charset=utf-8",
        },
      }),
    }),
    update: builder.mutation({
      query: ({ endpoint, id, body }) => ({
        url: `${endpoint}${id}`,
        method: "PUT",
        body: JSON.stringify(body),
      }),
    }),
    remove: builder.mutation({
      query: ({ endpoint, id }) => ({
        url: `${endpoint}${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetQuery,
  useAddMutation,
  useUpdateMutation,
  useRemoveMutation,
} = apiSlice;
export { url, api, buildApiUrl as buildUrl };
